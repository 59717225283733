














import { fromToChartTicks } from '@/mixins/statistics/FromToChartTicks'

import { ApexChartLocales } from 'piramis-base-components/src/shared/types/ApexChartSeries.types'

import Vue from 'vue'
import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import VueApexCharts from 'vue-apexcharts'
import { ApexOptions } from 'apexcharts'

@Component({
  components: {
    VueApexCharts,
  }
})
export default class TriggersStatChart extends Vue {
  @Prop() triggersStat!: Array<any>

  @Prop() from!: string

  @Prop() to!: string

  chartOptions: ApexOptions = {
    chart: {
      type: 'area',
      defaultLocale: this.$i18n.locale,
      locales: ApexChartLocales,
      animations: {
        enabled: false,
      },
    },
    stroke: {
      curve: 'smooth'
    },
    dataLabels: {
      enabled: false
    },
    xaxis: {
      labels: {
        datetimeUTC: false
      },
      type: "datetime",
      categories: fromToChartTicks(this.from, this.to).timeTicks,
    },
  };
}
