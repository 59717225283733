





































































import { InputSetups } from '@/mixins/input-setups'
import TriggerService from '../../includes/services/TriggerService'
import TriggerStatChart from '@/components/TriggerStatChart.vue'
import TriggerStatisticsMixin from '@/mixins/statistics/Triggers/TriggerStatisticsMixin'
import PageTitle from 'piramis-base-components/src/components/PageTitle.vue'
import MobileLandscapeTriggerLayout from '@/components/MobileLandscapeTriggerLayout.vue'

import ConfigField from 'piramis-base-components/src/components/ConfigField/ConfigField.vue'
import { UseFields } from 'piramis-base-components/src/components/Pi'
import { SelectOption } from 'piramis-base-components/src/logic/types'
import EmptyData from 'piramis-base-components/src/components/EmptyData/EmptyData.vue'

import moment from 'moment'
import Component from 'vue-class-component'
import { Mixins, Watch } from 'vue-property-decorator'
import { cloneDeep } from 'lodash'
import { Trigger, TriggerStatRecord } from '@/includes/types/Triggers'
import { fromToChartTicks } from '@/mixins/statistics/FromToChartTicks'

@Component({
  components: {
    PageTitle,
    TriggerStatChart,
    ConfigField,
    MobileLandscapeTriggerLayout,
    EmptyData
  }
})
export default class TriggersStatistics extends Mixins<UseFields, InputSetups, TriggerStatisticsMixin>(UseFields, InputSetups, TriggerStatisticsMixin) {

  selectedTriggers: Array<string> = []

  @Watch('selectedTriggers.length')
  onSelectedTriggersChange(length): void {
    if (!length) {
      this.triggersToChart = null
    }
  }

  trOptions: Array<SelectOption> | null = null

  triggersToChart: {} | null = null

  from = moment().add(-1, 'month').format('YYYY-MM-DD')

  to = moment().format('YYYY-MM-DD')

  key = 0

  prepareStatistics() {
    this.loadTriggerStatistics()
      .then((triggersStatistics) => {

        const filteredStatistics = triggersStatistics
          .filter(v => this.selectedTriggers.includes(v.key))
          .map(t => ({
            name: t.config.name,
            stat: t.stat || []
          }))

        const series = filteredStatistics.map(trigger => {
          return this.prepareTriggerCountStat(trigger.stat, trigger.name, this.from, this.to)
        })

        this.triggersToChart = { series, labels: fromToChartTicks(this.from, this.to).timeTicks }

        this.key += 1
      })
  }

  async loadTriggerStatistics(): Promise<Array<{stat: Array<TriggerStatRecord>} & Trigger>> {
    const { items } = await TriggerService.getTriggersStat('tg', {
      user_key: this.$store.state.userState.currentUserConfig?.user?.key,
      from: this.from,
      to: this.to,
    })

    return items
  }

  get triggerOptions() {
    if (this.trOptions) {
      return this.trOptions
    }

    return []
  }

  async created(): Promise<void> {
    const { items } = await TriggerService.getTriggersStat('tg', {
      user_key: this.$store.state.userState.currentUserConfig?.user?.key,
      from: moment().add(-31, 'd').format('YYYY-MM-DD'),
      to: moment().format('YYYY-MM-DD'),
    })

    if (items.length) {
      this.trOptions = items.map(t => ({
        label: t.config.name,
        value: t.key
      }))
    }
  }
}
