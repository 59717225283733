import { fromToChartTicks } from '@/mixins/statistics/FromToChartTicks'
import { TriggerStatRecord } from '@/includes/types/Triggers'

import Vue from 'vue'
import Component from 'vue-class-component'
import { cloneDeep } from 'lodash'

@Component
export default class TriggerStatisticsMixin extends Vue {

    prepareTriggerCountStat(statistics: Array<TriggerStatRecord>, triggerName:string, from, to): { name: string, data: Array<number> } {
        const { timeTicks, dataTicks } = fromToChartTicks(from, to)

        const name = triggerName;
        const data = dataTicks;

        statistics.forEach((record) => {
                const matchIndex = timeTicks.indexOf(record.time)

                data[matchIndex] = record.count
        })

        return cloneDeep({
            name,
            data
        })
    }

}